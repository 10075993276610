import { useQuery } from '@tanstack/react-query';
import { VehicleApi } from 'api';
import { ObjectHelper } from 'helpers';
import React from 'react';
import { useSelector } from 'react-redux';

/**
 * Custom hook for searching vehicles using React Query
 *
 * @param {object} searchValues
 * @param {{ onError?: (error: Error) => void  }} callbacks
 * @returns
 */
export function useSearchVehicles({ onError } = {}) {
  const searchValues = useSelector((state) => state.search);

  const query = React.useMemo(
    () =>
      ObjectHelper.trimQuery({
        ...searchValues.filter,
        ...searchValues.sortOption,
        statusFilters: searchValues.statusFilters,
        searchTerm: searchValues.searchTerm,
      }),
    [searchValues]
  );

  return {
    ...useQuery({
      queryKey: ['vehicles', query],
      queryFn: () => VehicleApi.search(query).catch((error) => onError?.(error)),
    }),
    searchValues,
  };
}
