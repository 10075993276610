import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { PaymentApi } from '../../../api';
import { REACT_APP_STRIPE_PUBLISH_KEY } from '../../../constants/config';
import { Notification, NOTIFICATION_TYPE } from '../../../components';

const stripe = loadStripe(REACT_APP_STRIPE_PUBLISH_KEY);

const Payment = () => {
  const [clientSecret, setClientSecret] = useState('');
  const [intent, setIntent] = useState('')

  useEffect( () => {
    (async () => {
      try {
        const response = await PaymentApi.secret()
        const data = response.data;
        setClientSecret(data.client_secret)
        setIntent(data)
      } catch (e) {
        Notification(NOTIFICATION_TYPE.DANGER, e.message);
      }
    })();
  }, [])

  const appearance = {
    theme: 'night',
    labels: 'floating'
  };

  const loader = 'auto';

  return (
    clientSecret && <Elements stripe={stripe} options={{clientSecret, appearance, loader}}>
      <CheckoutForm intent={intent}/>
    </Elements>
  );
};

export default Payment;
