import React from 'react';

export function BondReleaseTable({ vehicles }) {
  return (
    <table className="table table-striped table-sm">
      <caption>* Vehicles that do not exist are marked in red</caption>
      <thead>
        <tr>
          <th scope="col">No.</th>
          <th scope="col">Vin</th>
        </tr>
      </thead>
      <tbody>
        {vehicles.map((vehicle, index) => {
          return (
            <tr key={index}>
              <td className={!vehicle.isExisted ? 'text-danger' : 'text-white'}>{index + 1}</td>
              <td className={!vehicle.isExisted ? 'text-danger' : 'text-white'}>{vehicle.vin}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
