import * as Sentry from '@sentry/react';
import 'bootstrap';
import { render } from 'react-dom';
import store from './store/index';
import { Provider } from 'react-redux';

import App from './App';
import './index.scss';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE) || 1.0,
  tracePropagationTargets: [
    new RegExp(`^${process.env.FRONTEND_URL}`),
    new RegExp(`^${process.env.REACT_APP_BACKEND_URL}`),
  ],
  replaysSessionSampleRate: parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE) || 0.1,
  replaysOnErrorSampleRate: parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE) || 1.0,
});

const rootElement = document.getElementById('root');

render(
  <Provider store={store}>
    <App />
  </Provider>
  , rootElement);
