import React from 'react';
import { VEHICLE_STATUSES } from 'constants/vehicle';
import { setStatusFilters } from 'store/Slices/searchSlice';
import { useSelector, useDispatch } from 'react-redux';
import { CustomSelect } from 'components';
import './styles.scss';

const VehicleStatusFilters = () => {
  const dispatch = useDispatch();
  const searchValues = useSelector((state) => state.search);
  const vehicleStatusOptions = VEHICLE_STATUSES.map(
    (vehicleStatus) => ({  label: vehicleStatus, value: vehicleStatus.toLowerCase() })
  );
  const groupedVehicleStatusOptions = [
    {
      label: 'Presets',
      options: [
        { label: 'All', value: 'all' },
        { label: 'Not Vaulted', value: 'all_not_vaulted' },
      ]
    },
    { label: 'Statuses', options: vehicleStatusOptions },
  ];
  const selectedVehicleStatusOptions = searchValues.statusFilters.map(
    (statusFilter) => vehicleStatusOptions.find(
      (vehicleStatusOption) => vehicleStatusOption.value === statusFilter.toLowerCase()
    )
  );

  const handleChangeFilter = ({ action, option, removedValue }) => {
    switch (action) {
      case 'clear':
        return dispatch(setStatusFilters([]));
      case 'remove-value':
        return dispatch(setStatusFilters(
          searchValues.statusFilters.filter((status) => status.toLowerCase() !== removedValue.value)
        ));
      default:
        switch (option.value) {
          case 'all':
            return dispatch(setStatusFilters(VEHICLE_STATUSES));
          case 'all_not_vaulted':
            return dispatch(setStatusFilters(VEHICLE_STATUSES.filter((vehicleStatus) => vehicleStatus !== 'Vaulted')));
          default:
            return dispatch(setStatusFilters([...searchValues.statusFilters, option.label]));
        }
    }
  };

  return (
    <CustomSelect
      options={groupedVehicleStatusOptions}
      value={selectedVehicleStatusOptions}
      onChange={(_, event) => handleChangeFilter(event)}
      isMulti={true}
    />
  );
};

export default VehicleStatusFilters;
