import { configureStore } from '@reduxjs/toolkit'
import searchReducer from './Slices/searchSlice';
import vehicleTableReducer from './Slices/vehicleTableSlice';

export default configureStore({
    reducer: {
        search: searchReducer,
        vehicleTable: vehicleTableReducer
    }
})
