import { ROUTES } from 'constants/routes';
import { VEHICLE_STATUSES } from 'constants/vehicle';
import useVinScanner from 'providers/vinScannerProvider';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import { Button, NOTIFICATION_TYPE, Notification } from 'components';
import scrollToErrorViewById from 'components/FocusError/scrollToErrorViewById';

import BrokerInfo from './BrokerInfo';
import CustomerInfo from './CustomerInfo';
import DotInfo from './DotInfo';
import EPAEmissionInfo from './EPAEmissionInfo';
import GeneralInfo from './GeneralInfo';
import ManufacturerInfo from './ManufacturerInfo';
import TireLoadingInfo from './TireLoadingInfo';
import TransporterInfo from './TransporterInfo';
import useVehicleForm from './VehicleForm.hook';

/**
 * VehicleForm
 * @param {{action: 'create'|'edit', vehicle: object}} props
 * @returns {JSX.Element}
 */
const VehicleForm = ({ action = 'create', vehicle, setVehicle }) => {
  const { vin = '' } = useVinScanner();

  const [lastSaved, setLastSaved] = useState({});
  const notificationCallback = (type = NOTIFICATION_TYPE.DANGER, message = '') => {
    Notification(type, message);
  };
  const [form, loading, saveVehicle, vehicleUpdated, buttonDisable] = useVehicleForm(
    vehicle,
    vin,
    notificationCallback
  );

  const { values, setFieldValue } = form;
  useEffect(() => {
    if (!vehicleUpdated?.data?.vehicle) return;
    setVehicle(vehicleUpdated?.data?.vehicle);
  }, [vehicleUpdated]);
  const navigate = useNavigate();

  const generalInfoRef = useRef();

  const [buttonLoading, setButtonLoading] = useState('save');
  useEffect(() => {
    const errorKeys = Object.keys(form.errors);
    if (errorKeys.length > 0) {
      Notification(NOTIFICATION_TYPE.DANGER, `${form.errors[errorKeys[0]]}`);
      scrollToErrorViewById(form.errors);
    }
  }, [form.errors]);

  useEffect(() => {
    if (!generalInfoRef.current) {
      return;
    }

    if (vin.length > 0 && values && vin !== values.vin.trim()) {
      setFieldValue('vin', vin);
      generalInfoRef.current.checkVinFromScanner(vin);
    }
  }, [setFieldValue, values.vin, vin]);

  const [autosave, setAutosave] = React.useState(false);
  useEffect(() => {
    const autosave = setInterval(function () {
      setAutosave(true);
    }, 10 * 1000);
    return () => {
      setAutosave(false);
      clearInterval(autosave);
    };
  }, []);

  const handleAutoSave = async () => {
    setButtonLoading('Auto');
    let res = await saveVehicleCallback();
    setAutosave(false);
    return res;
  };
  useEffect(() => {
    if (
      values.vin === '' ||
      values.year === '' ||
      values.make === '' ||
      values.model === '' ||
      values.countryOfOrigin === '' ||
      form.isSubmitting ||
      values.entryDate === '' ||
      !autosave
    )
      return;
    handleAutoSave();
  }, [values]);

  const saveVehicleCallback = useCallback(
    async (type, cb = () => {}) => {
      return await saveVehicle(type, cb);
    },
    [values]
  );
  const handleSaveVehicle = async (
    buttonName = null,
    vehicleStatus = VEHICLE_STATUSES[0],
    cb = () => {}
  ) => {
    if (loading) {
      notificationCallback(NOTIFICATION_TYPE.DANGER, 'Please wait... still saving the vehicle');
      return;
    }
    setButtonLoading(buttonName);
    const res = await saveVehicleCallback(vehicleStatus, cb);
  };

  const handleNewImport = async () => {
    handleSaveVehicle('save', VEHICLE_STATUSES[1], (status) => {
      if (status === 200) {
        navigate(ROUTES.MAIN.VEHICLES.INDEX);
      }
      setButtonLoading('');
    });
  };
  const handleSaveNew = async () => {
    handleSaveVehicle('saveNew', null, (status) => {
      if (status === 200) {
        form.resetForm();
        window.scrollTo(0, 0);
      }
    });
  };
  const handleEditVehicle = async (type = VEHICLE_STATUSES[0]) => {
    handleSaveVehicle(type, type === VEHICLE_STATUSES[1] ? type : null, (status) => {
      if (status === 200) {
        navigate(-1);
      }
      setButtonLoading('');
    });
  };

  return (
    <form>
      <GeneralInfo
        ref={generalInfoRef}
        form={form}
        lastSaved={lastSaved}
        setLastSaved={setLastSaved}
        saveVehicle={handleSaveVehicle}
        vehicle={vehicle}
      />

      <hr />

      <ManufacturerInfo form={form} />

      <hr />

      <TireLoadingInfo form={form} />

      <hr />

      <TransporterInfo form={form} lastSaved={lastSaved} setLastSaved={setLastSaved} />

      <hr />

      <CustomerInfo form={form} lastSaved={lastSaved} setLastSaved={setLastSaved} />

      <hr />

      <EPAEmissionInfo form={form} vehicle={vehicle} />

      <hr />

      <DotInfo form={form} />

      <hr />

      <BrokerInfo form={form} />

      <hr />

      {action === 'create' ? (
        <div className="d-flex align-items-center justify-content-end gap-5">
          <Button
            disabled={buttonDisable}
            className="w-50"
            loading={loading && buttonLoading === 'save'}
            onClick={handleNewImport}
          >
            Save to Launchpad
          </Button>
          <Button
            disabled={buttonDisable}
            className="w-50"
            loading={loading && buttonLoading === 'saveNew'}
            onClick={handleSaveNew}
          >
            Save to Launchpad and add new
          </Button>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center gap-5">
          <Button className="flex-fill" onClick={() => navigate(-1)}>
            Back
          </Button>
          <Button
            disabled={buttonDisable}
            className="flex-fill"
            loading={loading && buttonLoading === VEHICLE_STATUSES[1]}
            onClick={() => {
              handleEditVehicle(VEHICLE_STATUSES[1]);
            }}
          >
            {'Save to '}
            {vehicle?.status === VEHICLE_STATUSES[1] || vehicle?.status === VEHICLE_STATUSES[0]
              ? VEHICLE_STATUSES[1]
              : vehicle?.status}
          </Button>
        </div>
      )}
    </form>
  );
};

export default VehicleForm;
