import { createSlice } from '@reduxjs/toolkit';
import { VEHICLE_STATUSES } from 'constants/vehicle';

const defaultStatusFilters = VEHICLE_STATUSES.filter((status) => status !== 'Vaulted');

export const defaultSearchValues = {
  searchTerm: '',
  filter: {
    page: 0,
    perPage: 10,
  },
  statusFilters: defaultStatusFilters,
  sortOption: {
    order: 'desc',
    orderBy: 'vin',
  },
};

const searchSlice = createSlice({
  name: 'search',
  initialState: defaultSearchValues,
  reducers: {
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },
    setFilters: (state, action) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    setStatusFilters: (state, action) => {
      state.statusFilters = action.payload ?? defaultStatusFilters;
    },
    setSortOption: (state, action) => {
      state.sortOption = { ...state.sortOption, ...action.payload };
    },
  },
});

export const {
  setSearchTerm,
  setFilters,
  setStatusFilters,
  setSortOption,
} = searchSlice.actions;

export default searchSlice.reducer;
