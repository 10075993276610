import { DialogContent } from '@mui/material';
import React from 'react';
import { Trash } from 'react-feather';
import { Button, Dialog, NOTIFICATION_TYPE, Notification } from 'components';
import UnstyledButton from 'components/UnstyledButton';
import VisuallyHidden from 'components/VisuallyHidden/VisuallyHidden';
import { useSelector } from 'react-redux';
import { BondReleaseTable } from './BondReleaseTable';
import { useUploadBondRelease } from './useUploadBondRelease';
import { useSearchVehicles } from 'features/vehicles/hooks/useSearchVehicles';

const BondReleaseModal = ({ show, handleClose }) => {
  const { refetch } = useSearchVehicles();
  const isVehicleTableLoaded = useSelector((state) => state.vehicleTable.isLoaded);

  const {
    dropzone,
    selectedFiles,
    isScanning,
    isUploading,
    vehicles,
    processedExistedVehiclesCount,
    handleRemoveSelectedFile,
    handleScanVin,
    handleUpload,
    handleClear,
  } = useUploadBondRelease();
  const totalExistedVehiclesCount = vehicles.filter((vehicle) => vehicle.isExisted).length;
  const processedExistedVihiclesPercentage = totalExistedVehiclesCount ? Math.round(processedExistedVehiclesCount * 100 / totalExistedVehiclesCount) : 0;

  return (
    <Dialog open={show} onClose={handleClose} title="Upload Bond Release">
      <DialogContent sx={{ py: 6 }} className={'photo-upload-form'}>
        <div className="mb-4">
          <div className="drop-box" {...dropzone.getRootProps()}>
            <input {...dropzone.getInputProps()} />
            {selectedFiles.length > 0 ? (
              <div className="file-list" style={{  maxHeight: '200px', overflowY: 'auto', display: 'flex', flexWrap: 'wrap', gap: '43px', padding: '11px' }}>
                {selectedFiles.map((selectedFile, selectedFileIndex) => (
                  <div key={selectedFileIndex} className={'d-flex justify-content-between align-items-center gap-4'}>
                    <div>{selectedFile.file.name}</div>
                    <UnstyledButton
                      className="remove-btn position-relative"
                      disabled={isScanning || isUploading}
                      onClick={(event) => handleRemoveSelectedFile(selectedFileIndex, event)}
                    >
                      <Trash className="remove-icon" />
                      <VisuallyHidden>Clear file</VisuallyHidden>
                    </UnstyledButton>
                  </div>
                ))}
              </div>
              
            ) : (
              <>
                <p className="text-md text-grey mb-0">Drag and Drop file</p>
                <p className="text-sm text-grey-dark mb-0">PDF</p>
              </>
            )}
          </div>
        </div>
        {vehicles.length > 0 && <BondReleaseTable vehicles={vehicles} />}
        {vehicles.length > 0 && isUploading && (
          <div style={{ marginTop: '10px' }}>
            <p>{processedExistedVehiclesCount} of {totalExistedVehiclesCount} files uploaded</p>
            <progress value={processedExistedVihiclesPercentage} max="100" style={{ width: '100%' }} />
            <p>{processedExistedVihiclesPercentage}%</p>
          </div>
        )}
        <Button
          className={'float-end'}
          type="button"
          disabled={vehicles.length === 0 || totalExistedVehiclesCount === 0 || isScanning}
          onClick={async () => {
            const count = await handleUpload();
            if (count > 0) {
              Notification(
                NOTIFICATION_TYPE.SUCCESS,
                `Successfully released ${count} vehicle${count > 1 ? 's' : ''}`
              );
            }
            handleClose();
            if (isVehicleTableLoaded) {
              refetch();
            }
          }}
          loading={isUploading}
        >
          Upload
        </Button>
        <Button
          className={'float-end'}
          type="button"
          disabled={selectedFiles.length === 0}
          onClick={async () => {
              handleScanVin();
          }}
          style={{ marginRight: '10px' }}
          loading={isScanning}
        >
          Scan Vin
        </Button>
        <Button
          className={'float-end'}
          type="button"
          disabled={selectedFiles.length === 0 && vehicles.length === 0}
          onClick={async () => {
            handleClear();
          }}
          style={{ marginRight: '10px' }}
          variant="dark"
        >
          Clear
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default BondReleaseModal;
