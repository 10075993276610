import { useMutation, useQueryClient } from '@tanstack/react-query';
import { VehicleApi } from 'api';
import download from 'downloadjs';
import { AuthContext } from 'providers/authProvider';
import React from 'react';
import { isRegisteredImporter } from 'services/auth';

export function useGenerateNHTSAPackage() {
  const { user } = React.useContext(AuthContext);

  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['generate-NHTSA-package'],
    mutationFn: async ({ vehicleList, checkedIds, isResubmission, originalPackageId }) => {
      try {
        if (!isRegisteredImporter(user)) {
          return;
        }

        for (const id of checkedIds) {
          const vehicle = vehicleList.find((v) => v.id === id);
          if (vehicle && !vehicle.nhtsaReady) {
            return Promise.reject({
              message: 'Please select vehicles that have nhtsaReady is ready',
            });
          }
        }

        const res = await VehicleApi.generateNHTSAPackage({
          ids: checkedIds,
          isResubmission,
          dateSigned: new Date().toISOString().split('T')[0],
          originalPackageId,
        });

        const content = res.headers['content-type'];
        download(res.data, 'NHTSAPackage.zip', content);

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    onSuccess: (data, variables) => {
      const { isResubmission } = variables;

      if (isResubmission) {
        queryClient.invalidateQueries(['nhtsa-packages']);
      } else {
        queryClient.invalidateQueries(['vehicles']);
      }
    },
  });
}
