import { GlobalWorkerOptions, getDocument, version } from 'pdfjs-dist';
import React from 'react';

GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${version}/build/pdf.worker.mjs`;

export function useRenderPdfToCanvas({ file, scale = 0.2, fetchFn }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const canvasRef = React.useRef();

  const readFileContent = React.useCallback(async () => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        resolve({
          data: window.atob(reader.result.split(';base64,')[1]),
        });
      });
      reader.addEventListener('error', reject);
      reader.readAsDataURL(file);
    });
  }, [file]);

  const pdfFetcher = fetchFn ?? readFileContent;

  const renderPage = React.useCallback(async ({ pdfDoc, pageNum, scale }) => {
    const page = await pdfDoc.getPage(pageNum);

    const ctx = canvasRef.current.getContext('2d');

    const viewport = page.getViewport({ scale });

    canvasRef.current.width = viewport.width;
    canvasRef.current.height = viewport.height;

    page.render({
      canvasContext: ctx,
      viewport: viewport,
    });
  }, []);

  React.useEffect(() => {
    if (!file) return;

    const fetchPdf = async () => {
      try {
        setIsLoading(true);

        const filecontent = await pdfFetcher(file);

        const pdfDoc = await getDocument({
          data: filecontent.data,
        }).promise;

        await renderPage({ pdfDoc, pageNum: 1, scale });

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchPdf();
  }, [file, pdfFetcher, renderPage, scale]);

  return { isLoading, canvasRef };
}
