import { createSlice } from '@reduxjs/toolkit';

const vehicleTableSlice = createSlice({
  name: 'vehicleTable',
  initialState: {
    isLoaded: false,
  },
  reducers: {
    setVehicleTableLoaded: (state, action) => {
      state.isLoaded = action.payload;
    },
  },
});

export const { setVehicleTableLoaded } = vehicleTableSlice.actions;

export default vehicleTableSlice.reducer;
