import Http from 'utilities/http';

export class PlacesApi {
  static async autocomplete({ input = '', radius = 50000, components = '', types = 'address' }) {
    return Http.get('/places', {
      input,
      radius,
      components,
      types,
    }).then((res) => res.data);
  }

  static async getPlaceDetails(id, query) {
    return Http.get(`/places/${id}`, query).then((res) => res.data);
  }
}
