export const VEHICLE_STATUSES = [
  'Initiation',
  'Launchpad',
  'Submission',
  'Compliance',
  'Resubmission',
  'Vaulted',
];

export const ENTRY_STATUS = {
  submitted: 'SUBMITTED',
  success: 'SUCCESS',
  failed: 'FAILED',
};
