import React from 'react';
import { GlobalWorkerOptions, version } from 'pdfjs-dist';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import { NOTIFICATION_TYPE, Notification } from 'components';

GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${version}/build/pdf.worker.mjs`;

export function useUploadBondRelease() {
  const MAX_UPLOAD_FILES = 100;
  const MAX_WORKERS = 10;
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [vehicles, setVehicles] = React.useState([]);
  const [isScanning, setIsScanning] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [processedExistedVehiclesCount, setProcessedExistedVehiclesCount] = React.useState(0);

  const dropzone = useDropzone({
    onDrop: function (uploadedFiles) {
      // Filter out uploaded files that are already in selected files.
      const newSelectedFiles = uploadedFiles.filter(
        (newSelectedFile) => !selectedFiles.some(
          (selectedFile) => selectedFile.file.name === newSelectedFile.name && selectedFile.file.lastModified === newSelectedFile.lastModified
        )
      );
      const alreadySelectedFiles = uploadedFiles.filter(
        (newSelectedFile) => selectedFiles.some(
          (selectedFile) => selectedFile.file.name === newSelectedFile.name && selectedFile.file.lastModified === newSelectedFile.lastModified
        )
      );
      alreadySelectedFiles.forEach((alreadySelectedFile) => {
        Notification(NOTIFICATION_TYPE.INFO, `${alreadySelectedFile.name} is already selected.`);
      });
      let newSelectedFilesToAdd = [];
      if (newSelectedFiles.length + selectedFiles.length > MAX_UPLOAD_FILES) {
        // Limit the number of files to MAX_UPLOAD_FILES
        newSelectedFilesToAdd = newSelectedFiles.slice(0, MAX_UPLOAD_FILES - selectedFiles.length);
        Notification(NOTIFICATION_TYPE.DANGER, `You can upload up to ${MAX_UPLOAD_FILES} files.`);
      } else {
        newSelectedFilesToAdd = newSelectedFiles;
      }
      newSelectedFilesToAdd = newSelectedFilesToAdd.map((newSelectedFile) => {
        return {
          id: uuidv4(),
          file: newSelectedFile,
        };
      });
      console.log('newSelectedFilesToAdd', newSelectedFilesToAdd);
      setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...newSelectedFilesToAdd]);
    },
    multiple: true,
    accept: 'application/pdf',
  });

  const handleRemoveSelectedFile = (selectedFileIndexToRemove, event) => {
    console.log('vehicles', vehicles);
    console.log('selectedFileIndexToRemove', selectedFileIndexToRemove);
    event.stopPropagation();
    const selectedFileToRemove = selectedFiles[selectedFileIndexToRemove];
    setVehicles((prevVehicles) => {
      return prevVehicles.filter((prevVehicle) => prevVehicle.selectedFileID !== selectedFileToRemove.id);
    });
    setSelectedFiles((prevSelectedFiles) => {
      return prevSelectedFiles.filter((_, prevSelectedFileIidex) => prevSelectedFileIidex !== selectedFileIndexToRemove);
    });
  };

  const handleClear = () => {
    setSelectedFiles([]);
    setVehicles([]);
  };

  const handleScanVin = React.useCallback(async () => {
    setIsScanning(true);
    console.log('handleScanVin start');
    let workerCount = MAX_WORKERS;
    let workers = [];
    let workerPromises = [];
    const selectedFilesIndexesQueue = Array.from(selectedFiles.keys());
    console.log('selectedFiles', selectedFiles);
    console.log('selectedFilesIndexesQueue', selectedFilesIndexesQueue);
    setVehicles([]);
    setProcessedExistedVehiclesCount(0);
    if (selectedFiles.length < workerCount) {
      workerCount = selectedFiles.length;
    }
    for (let i = 0; i < workerCount; i++) {
      workerPromises[i] = new Promise((resolve, reject) => {
        workers[i] = new Worker(new URL('workers/vinDetectionWorker.js', import.meta.url));
        workers[i].onmessage = function (event) {
          if (event.data.processed) {
            if (event.data.success === true) {
              // Vehicle found
              console.log('Main thread: Message received from Worker ' + i, event.data);
              setVehicles((prevVehicles) => {
                // Check if is a duplicatie
                if (prevVehicles.some((prevVehicle) => prevVehicle.vin === event.data.vin)) {
                  Notification(NOTIFICATION_TYPE.WARNING, `VIN ${event.data.vin} is already selected.`);
                  return prevVehicles;
                }
                return [
                  ...prevVehicles,
                  {
                    selectedFileID: event.data.selectedFileID,
                    vin: event.data.vin,
                    isExisted: true,
                    isReleased: event.data.vehicle.isReleased,
                  },
                ];
              });
            } else {
              // Vehicle not found or error
              console.log('Main thread: Error from Worker ' + i, event.data.error);
              setVehicles((prevVehicles) => {
                // Check if is a duplicatie
                if (prevVehicles.some((v) => v.vin === event.data.vin)) {
                  Notification(NOTIFICATION_TYPE.WARNING, `VIN ${event.data.vin} is already selected.`);
                  return prevVehicles;
                }
                return [
                  ...prevVehicles,
                  {
                    selectedFileID: event.data.selectedFileID,
                    vin: event.data.vin,
                    isExisted: false,
                    isReleased: false,
                  },
                ];
              });
            }
            if (selectedFilesIndexesQueue.length > 0) {
              let nextSelectedFileIndex = selectedFilesIndexesQueue.shift();
              console.log(`Sending file ${nextSelectedFileIndex} to worker ${i}`);
              workers[i].postMessage({ selectedFile: selectedFiles[nextSelectedFileIndex] });
            } else {
              console.log('No more files to process, termintating worker ' + i);
              workers[i].terminate();
              resolve();
            }
          }
        };
        workers[i].onerror = function (event) {
          console.log('Main thread: Error from Worker ' + i, event);
          reject();
        };
        if (selectedFilesIndexesQueue.length > 0) {
          let nextSelectedFileIndex = selectedFilesIndexesQueue.shift();
          console.log(`Sending file ${nextSelectedFileIndex} to worker ${i}`);
          workers[i].postMessage({ selectedFile: selectedFiles[nextSelectedFileIndex] });
        } else {
          console.log('No more files to process, termintating worker ' + i);
          workers[i].terminate();
          resolve();
        }
      });
    }
    await Promise.all(workerPromises);
    setIsScanning(false);
    console.log('handleScanVin end');
  }, [selectedFiles]);

  const handleUpload = React.useCallback(async () => {
    setIsUploading(true);
    console.log('handleUpload start');
    console.log('vehicles', vehicles);
    let count = 0;
    setProcessedExistedVehiclesCount(0);
    const existedVehiclesIndexesQueue = [];
    vehicles.forEach((vehicle, vehicleIndex) => {
      if (vehicle.isExisted) {
        existedVehiclesIndexesQueue.push(vehicleIndex);
      }
    });
    console.log('existedVehiclesIndexesQueue', existedVehiclesIndexesQueue);
    let workerCount = MAX_WORKERS;
    let workers = [];
    let workerPromises = [];
    if (existedVehiclesIndexesQueue.length < workerCount) {
      workerCount = existedVehiclesIndexesQueue.length;
    }
    for (let i = 0; i < workerCount; i++) {
      workerPromises[i] = new Promise((resolve, reject) => {
        workers[i] = new Worker(new URL('workers/uploadBondReleaseWorker.js', import.meta.url));
        workers[i].onmessage = function (event) {
          if (event.data.processed) {
            if (event.data.success === true) {
              // Document is uploaded and vehicle tatus updated
              console.log('Main thread: Message received from Worker ' + i, event.data);
              count++;
              setProcessedExistedVehiclesCount(count);
            } else {
              // Vehicle not found or error
              console.log('Main thread: Error from Worker ' + i, event.data.error);
              Notification(NOTIFICATION_TYPE.DANGER, `File for VIN ${event.data.vin} was not uploaded`);
            }
            if (existedVehiclesIndexesQueue.length > 0) {
              let nextExistedVehicleIndex = existedVehiclesIndexesQueue.shift();
              console.log(`Sending vehicle ${nextExistedVehicleIndex} to worker ${i}: `, vehicles[nextExistedVehicleIndex]);
              let selectedFile = selectedFiles.find((selectedFile) => selectedFile.id === vehicles[nextExistedVehicleIndex].selectedFileID);
              workers[i].postMessage({ vin: vehicles[nextExistedVehicleIndex].vin, selectedFile });
            } else {
              console.log('No more vehicles to process, termintating worker ' + i);
              workers[i].terminate();
              resolve();
            }
          }
        };
        workers[i].onerror = function (event) {
          console.log('Main thread: Error from Worker ' + i, event);
          reject();
        };
        if (existedVehiclesIndexesQueue.length > 0) {
          let nextExistedVehicleIndex = existedVehiclesIndexesQueue.shift();
          console.log(`Sending vehicle ${nextExistedVehicleIndex} to worker ${i}: `, vehicles[nextExistedVehicleIndex]);
          let selectedFile = selectedFiles.find((selectedFile) => selectedFile.id === vehicles[nextExistedVehicleIndex].selectedFileID);
          workers[i].postMessage({ vin: vehicles[nextExistedVehicleIndex].vin, selectedFile });
        } else {
          console.log('No more vehicles to process, termintating worker ' + i);
          workers[i].terminate();
          resolve();
        }
      });
    }
    await Promise.all(workerPromises);
    handleClear();
    setIsUploading(false);
    console.log('handleUpload end');
    return Promise.resolve(count);
  }, [selectedFiles, vehicles]);

  return {
    dropzone,
    selectedFiles,
    vehicles,
    processedExistedVehiclesCount,
    isScanning,
    isUploading,
    handleRemoveSelectedFile,
    handleScanVin,
    handleUpload,
    handleClear,
  };
}
