import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SearchBar } from 'features/common/search/SearchBar';
import { useSearchVehicles } from 'features/vehicles/hooks/useSearchVehicles';
import { setSearchTerm } from 'store/Slices/searchSlice';

const VehicleSearch = () => {
  const { refetch } = useSearchVehicles();
  const dispatch = useDispatch();

  const handleSearch = React.useCallback(
    (debouncedSearchTerm) => {
      dispatch(setSearchTerm(debouncedSearchTerm))
    },
    [dispatch]
  );

  return <SearchBar fetch={refetch} onSearch={handleSearch} />;
};

export default VehicleSearch;
